.root {
}

.input {
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}

.labelSuccess {
  color: var(--colorGrey700);
}
