@import '../../styles/customMediaQueries.css';


.root {
}

.link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: var(--textColor);

  &:hover,
  &:active,
  &:focus {
    color: var(--colorSuccessDark);
    text-decoration: none;
    cursor: pointer;
  }
}

.linkIcon {

}

.linkLabel {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 12px;
  }
}

.linksListHolder {
  overflow: auto;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (--viewportCustomLarge) {
    padding: 0 70px 0 150px;
  }
}

.linksList {
  display: flex;
  align-items: center;
  padding: 0 0 0 24px;

  @media (--viewportCustomLarge) {
    padding: 0 0 0 55px;
  }

}

.linksItem {
  flex: 0 0 20%;
  max-width: 20%;
  margin-right: 15px;

  @media (--viewportSmall) {
    flex: 0 0 12%;
    max-width: 12%;
    margin-right: 15px;
  }

  @media (--viewportLargeWithPaddings) {
    flex: 0 0 8%;
    max-width: 8%;
    margin-right: 25px;
  }
}
