@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
}

.input {
  pointer-events: none;
}

.inputGroup {
  display: flex;
  align-items: center;
  width: 100px;

  & input[type="number"] {
    -moz-appearance: textfield;
    padding: 2px;
    height: 26px;
    font-size: 14px;
    text-align: center;
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.decrementButton, .incrementButton {
  width: 20px;
  height: 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex-shrink: 0;
  border: 0;
}

.decrementButton:disabled, .incrementButton:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

